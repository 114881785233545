import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { SERVICES } from '../../../Services/Services';

export const AddressForm = ({ show, handleClose, fetchData }) => {
    const [street, setStreet] = useState("");
    const [neighbordhood, setNeighbordhood] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [cp, setCp] = useState("");
    const [extNum, setExtNum] = useState("");
    const [intNum, setIntNum] = useState("");

    const errorView = (title, message) => {
        // Alert.alert(`${title}`, `${message}`, [{ text: 'Entendido' }])
    }

    const createAddress = async (e) => {
        // console.log('disparo')
        e.preventDefault();
        if (
            // name.trim() === "" ||
            street.trim() === "" ||
            neighbordhood.trim() === "" ||
            city.trim() === "" ||
            state.trim() === "" ||
            country.trim() === "" ||
            cp.trim() === "" ||
            extNum.trim() === ""
        ) return errorView('Complete el formulario', '');
        const data = {
            "address": {
                "street": street.trim(),
                "neighborhood": neighbordhood.trim(),
                "city": city.trim(),
                "state": state.trim(),
                "country": country.trim(),
                "postal_code": cp.trim(),
                "int_num": intNum.trim(),
                "ext_num": extNum.trim()
            }
        }
        await SERVICES.addAddress(data).then((res) => {
            // console.log('res ->', res);
            handleClose();
            fetchData();
            // navigation.goBack();
        }).catch((error) => {
            // console.log('error ->', error)
        })
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Completa el formulario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={createAddress}>
                    <label className='form-text ps-2'>Calle</label>
                    <input className='form-control' value={street} onChange={({ target }) => setStreet(target.value)}/>
                    <label className='form-text ps-2'>Colonia</label>
                    <input className='form-control' value={neighbordhood} onChange={({ target }) => setNeighbordhood(target.value)}/>
                    <label className='form-text ps-2'>Ciudad</label>
                    <input className='form-control' value={city} onChange={({ target }) => setCity(target.value)}/>
                    <label className='form-text ps-2'>Estado</label>
                    <input className='form-control' value={state} onChange={({ target }) => setState(target.value)}/>
                    <label className='form-text ps-2'>Pais</label>
                    <input className='form-control' value={country} onChange={({ target }) => setCountry(target.value)}/>
                    <label className='form-text ps-2'>Codigo postal</label>
                    <input className='form-control' value={cp} onChange={({ target }) => setCp(target.value)}/>
                    <label className='form-text ps-2'>Numerio exterior</label>
                    <input className='form-control' value={extNum} onChange={({ target }) => setExtNum(target.value)}/>
                    <label className='form-text ps-2'>Numero interior</label>
                    <input className='form-control' value={intNum} onChange={({ target }) => setIntNum(target.value)}/>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={createAddress}>
                    Crear direccion
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
