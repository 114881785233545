import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { CartProducts } from '../../../Layouts/CartProducts/CartProducts';
import './ViewCart.style.css';

export const ViewCart = () => {
    const history = useHistory();
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);

    
    const fetchData = async () => {
        let productos = localStorage.getItem('SHOPPINGCART');
        productos = JSON.parse(productos);
        productos = !productos ? [] : productos
        let subTotal = 0;
        productos.map((item, index) => {
            subTotal = subTotal + (item.quantity * item.price);
        });
        setTotal(subTotal);
        // console.log('products ->', productos);
        setProducts(productos);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        products.length === 0 ?
            <div className='address-container mt-5'>
                <img src={require('../../../Assets/carrito-vacio.png')} className='mx-auto d-block w-25' alt='Carrito vacio' />
                <p className='fs-2 fw-bold text-center'>El carrito se encuentra vacio</p>
            </div>
            :
            <div className='mx-4 address-container'>
                <div className='row border-bottom text-center p-2 fs-5 fw-2 mb-4'>
                    <div className='col'>
                        <p>Imagen</p>
                    </div>
                    <div className='col'>
                        <p>Nombre</p>
                    </div>
                    <div className='col total-camp'>
                        <p>Precio</p>
                    </div>
                    <div className='col'>
                        <p>Cantidad</p>
                    </div>
                    <div className='col total-camp'>
                        <p>Total</p>
                    </div>
                    <div className='col'>
                        <p>Eliminar</p>
                    </div>
                </div>
                {products.map((item, index) => (
                    <CartProducts setTotal={setTotal} product={item} index={index} key={index} setProducts={setProducts} />
                ))}
                <div className='mt-3 pe-2 border-top text-end'>
                    <p className='fs-5 subtotal-p-title'>Subtotal: ${Number(total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                    <div className='d-flex pt-5'>
                        <button onClick={(e) => {e.preventDefault(); history.push('/Home')}} className='btn btn-outline-primary rounded-0 me-auto w-25'>
                            Seguir comprando
                        </button>
                        <button onClick={(e) => {e.preventDefault(); history.push('/ConfirmOrder')}} className='btn btn-outline-primary rounded-0 w-25'>
                            Proceder a pagar
                        </button>
                    </div>
                </div>
            </div>
    )
}
