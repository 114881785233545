import React from 'react';
import './Warranty.style.css';

export const Warranty = () => {
    return (
        <div className='container'>
            <p className='fs-3 text-center fw-bold py-3'>Politicas de garantia</p>
            <div className="d-flex flex-column justify-content-center mx-5">
                <p className='text-justify text-break'>
                    Todos los equipos, comercializados por Smartdepot gozan de una GARANTÍA LIMITADA, que es única y exclusiva contra defectos en su fabricación, ofreciendo una reparación o sustitución del equipo dentro del periodo de vigencia de dicha garantía, siempre y cuando se encuentren bajo condiciones de uso normal. Todo equipo está sujeto a revisión y diagnóstico para el trámite de garantía.En caso de que la parte o pieza a garantía no presente falla por defecto de fábrica, la empresa no está obligada a realizar ningún cambio del producto o devolución de efectivo.
                </p>
                <p className='mt-3 fw-bold'>Para hacer valida la garantía de un producto el cliente deberá:</p>
                <ul className='mt-2'>
                    <li>Presentar ticket o factura que avale la compra del producto.</li>
                    <li>Presentar el producto completo con su empaque, accesorios, etc.</li>
                </ul>
                <p className='mt-3 fw-bold'>La GARANTÍA SE INVALIDARA en los siguientes casos:</p>
                <ul className='mt-2'>
                    <li>Cuando los sellos de garantía o número de serie se encuentren rotos, manipulados, cambiados, removidos.</li>
                    <li>Daños causados por descargas eléctricas o uso con voltaje incorrecto.</li>
                    <li>Daños generados  por presencia de elementos nocivos (líquidos, acido, polvo, cuerpos orgánicos o inorgánicos, sustancias químicas, etc.) que no forman parte del equipos.</li>
                    <li>Daños ocasionados por accesorios o equipos externos no diseñados específicamente para dicho producto.</li>
                    <li>Daños causados por la instalación de otro software diferente al instalado de fábrica (sistema operativo, virus informático, etc.).</li>
                    <li>Cuando el producto haya sido alterado o reparado por personas no autorizadas por SMARTDEPOT.</li>
                    
                </ul>
                <p className='mt-3'>La Empresa no se hace responsable por daño o perdida de información guardada en medios de almacenamiento de los equipos o productos, esta es exclusiva responsabilidad del cliente.</p>
                <p className='mt-2'>POR POLÍTICA DE LA EMPRESA:</p>
                <ul>
                    <li>No se aceptan equipos sin comprobante de compra, ticket o factura sin excepción.</li>
                    <li>No se hacen devoluciones de efectivo.</li>
                    <li>No se recibe a garantía ningún equipo con daño físico (roto, quebrado, golpeado, mojado, quemado o con manipulación no autorizada, etc.).</li>
                    <li>No es responsabilidad de la empresa el daño o pérdida de información guardada en equipos  o productos  con medios de almacenamiento.</li>
                </ul>
                <p className='mt-3'>Todo equipo está sujeto a la revisión y diagnostico sin excepción. Y se entrega de vuelta sólo presentando su comprobante de recepción.</p>
            </div>
        </div>
    )
}
