import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SERVICES } from '../../../Services/Services';
import { logIn } from '../../../Store/Profile/ProfileStatus';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import './CreateAccount.style.css';
import Swal from 'sweetalert2';

export const CreateAccount = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("");
    const [messageError, setMessageError] = useState("");
    const [errorToast, setErrorToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [messageToast, setMessageToast] = useState("");

    const errorView = (title, message) => {
        // console.log('message ->', message)
        setMessageError(title + message);
        setIsLoading(false);
    }

    const createAccount = async () => {
        setIsLoading(true);

        if (
            name.trim() === "" ||
            lastName.trim() === "" ||
            email.trim() === "" ||
            phone.trim() === "" ||
            password.trim() === "" ||
            confirmPassword.trim() === ""
        ) return errorView('Complete el formulario', '');
        if (password.trim().length < 6 || confirmPassword.trim().length < 6) return errorView('Las contraseñas deben ser de 6 o mas caracteres', '');
        if (password.trim() !== confirmPassword.trim()) return errorView('Las contraseñas deben ser iguales', '');
        if (phone.length !== 10) return errorView('El telefono debe ser de 10 caracteres unicamente');
        const data = {
            "user": {
                "email": email.trim(),
                "name": name.trim(),
                "lastname": lastName.trim(),
                "phone": phone.trim(),
                "avatar": "",
                "password": password.trim(),
                "password_confirmation": confirmPassword.trim()
            }
        };
        await SERVICES.createAccount(data.user).then(async (res) => {
            // console.log('response create ->', res);
            if (res.result === false) {
                console.log('entro');
                // setMessageToast(res.message);
                // setErrorToast(true);
                setIsLoading(false);
                Swal.fire({title: 'La cuenta no pudo ser creada', text:res.message, icon:'error', confirmButtonColor:'red'});
                // setTimeout(() => {
                //     setErrorToast(false);
                // }, 6000);
            }else{
                Swal.fire({title: 'Cuenta creada correctamente', icon:'success', confirmButtonColor:'#00afef'});
                localStorage.setItem('USER', JSON.stringify(res.user));
                dispatch(logIn(res.user));
                history.push('/');
            }
            // if (res.error) return errorView('Algo salio mal', ``);
        }).catch((error) => {
            // console.log('entro')
            // console.log('error ->', error)
            setIsLoading(false);
        })
    }


    return (
        <div className='container'>
            <div className='p-4'>
                <p className='ptitle-create-account'>Crear cuenta</p>
                <p className='psubtitle-create-account'>Completa nuestro formulario y comienza a conocer a nuestros productos</p>

                <form className='border p-4 mt-4'>
                    <div className="">
                        <div className="">
                            <p className='p-label-create-account'>Nombre</p>
                            <input
                                onChange={({ target }) => setName(target.value)}
                                className='border form-label p-2 w-75 input-login-form'
                            />
                            <p className='p-label-create-account'>Apellido</p>
                            <input
                                onChange={({ target }) => setLastName(target.value)}
                                className='border form-label p-2 w-75 input-login-form'
                            />
                            <p className='p-label-create-account'>Numero de Celular</p>
                            <input
                                onChange={({ target }) => setPhone(target.value)}
                                type={'tel'}
                                className='border form-label p-2 w-75 input-login-form'
                            />
                        </div>
                        <div className="">
                            <p className='p-label-create-account'>Correo electronico</p>
                            <input
                                onChange={({ target }) => setEmail(target.value)}
                                type={'email'}
                                className='border form-label p-2 w-75 input-login-form'
                            />
                            <p className='p-label-create-account'>Contraseña</p>
                            <input
                                onChange={({ target }) => setPassword(target.value)}
                                type={'password'}
                                className='border form-label p-2 w-75 input-login-form'
                            />
                            <p className='p-label-create-account'>Confirmar contraseña</p>
                            <input
                                onChange={({ target }) => setConfirmPassword(target.value)}
                                type={'password'}
                                className='border form-label p-2 w-75 input-login-form'
                            />
                        </div>
                    </div>
                </form>
                <p className='text-danger'>{messageError}</p>
                <button disabled={isLoading} onClick={() => createAccount()} className='btn btn-outline-primary my-4 px-5 rounded-0'>
                    Crear cuenta <i className="bi bi-person-bounding-box"></i>
                </button>
                <ToastContainer position='bottom-end'>
                    <Toast bg='danger' show={errorToast} onClose={() => setErrorToast(false)}>
                        <Toast.Header>
                            <strong className="me-auto">Ups...</strong>
                        </Toast.Header>
                        <Toast.Body className='text-white'>{messageToast}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </div>
    )
}
