import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SERVICES } from '../../../Services/Services';

export const ChangePassword = () => {
    const history = useHistory();
    const [pass, setPass] = useState("");
    const [pass_confirm, setPass_confirm] = useState("");




    const reset = async (e) => {
        e.preventDefault();
        if (pass.length < 6 || pass_confirm.length < 6) return console.log('debe ser mayor a 6 caracteres');
        if (pass !== pass_confirm) return console.log('deben de ser iguales');
        const data = {
            password: pass,
            password_confirmation: pass_confirm
        }
        await SERVICES.updatePassword(data).then((res) => {
            history.goBack();
        }).catch((error) => {
            console.log('error ->', error);
        })

    }
    return (
        <div className=''>
            <div className='d-flex flex-column w-50 justify-content-center m-4'>
                <div className="mb-3 row">
                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Nueva contraseña</label>
                    <div className="col-sm-10">
                        <input value={pass} onChange={({ target }) => setPass(target.value)} type="password" className="form-control" id="inputPassword"/>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Confirmar nueva contraseña</label>
                    <div className="col-sm-10">
                        <input value={pass_confirm} onChange={({ target }) => setPass_confirm(target.value)} type="password" className="form-control" id="inputPassword"/>
                    </div>
                </div>
                <button onClick={reset} className='btn btn-outline-primary'>
                    Cambiar contraseña
                </button>
                
            </div>
        </div>
    )
}
