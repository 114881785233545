import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { Footer } from "../Layouts/Footer";
import { Navbar } from "../Layouts/Navbar";
import { ConfirmOrder } from "../Pages/Cart/ConfirmOrder/ConfirmOrder";
import { ViewCart } from "../Pages/Cart/ViewCart/ViewCart";
import { Categories } from "../Pages/Categories";
import { Home } from "../Pages/Home";
import { Privacy } from "../Pages/Policies/Privacy/Privacy";
import { Returns } from "../Pages/Policies/Returns/Returns";
import { Services } from "../Pages/Policies/Services/Services";
import { Shipment } from "../Pages/Policies/Shipment/Shipment";
import { Warranty } from "../Pages/Policies/Warranty";
import { Product } from "../Pages/Product";
import { ChangePassword } from "../Pages/Profile/ChangePassword/ChangePassword";
import { CreateAccount } from "../Pages/Profile/CreateAccount/CreateAccount";
import { LogIn } from "../Pages/Profile/LogIn";
import { MyAddress } from "../Pages/Profile/MyAddress/MyAddress";
import { MyOrders } from "../Pages/Profile/MyOrders/MyOrders";
import { MyPaymenths } from "../Pages/Profile/MyPaymenths/MyPaymenths";
import { MyProfile } from "../Pages/Profile/MyProfile/MyProfile";
import { PaymenthCards } from "../Pages/Profile/PaymenthCards/PaymenthCards";
import { ViewOrder } from "../Pages/Profile/ViewOrder/ViewOrder";
import { Search } from "../Pages/Search";
import { logIn } from "../Store/Profile/ProfileStatus";
import { getBadgeNum } from "../Store/Slices/badgeNumCounter";
import LogedRoutes from "./LogedRoutes";
import NoSessionRoutes from "./NoSessionRoutes";

const AppRoutes = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("USER")) {
            dispatch(logIn(JSON.parse(localStorage.getItem("USER"))));
        }
        dispatch(getBadgeNum())

    }, []);

    return (
        <div className="">
            <Navbar />
            <Switch>
                <Route path={'/Home'} component={Home} />
                <Route path={'/Product/:idProduct'} component={Product} />
                <Route path={'/Categories/:idCategory'} component={Categories} />
                <Route path={'/Search/:searchText'} component={Search} />
                <Route path={'/Warranty'} component={Warranty} />
                <Route path={'/Privacy'} component={Privacy} />
                <Route path={'/Returns'} component={Returns} />
                <Route path={'/Shipment'} component={Shipment} />
                <Route path={'/Services'} component={Services} />
                <NoSessionRoutes path={'/LogIn'} component={LogIn} />
                <NoSessionRoutes path={'/CreateAccount'} component={CreateAccount} />
                <LogedRoutes path={'/MyProfile'} component={MyProfile}/>
                <LogedRoutes path={'/MyAddress'} component={MyAddress}/>
                <LogedRoutes path={'/MyPaymenths'} component={MyPaymenths}/>
                <LogedRoutes path={'/Paymenth'} component={PaymenthCards}/>
                <LogedRoutes path={'/MyOrders'} component={MyOrders}/>
                <LogedRoutes path={'/Cart'} component={ViewCart}/>
                <LogedRoutes path={'/ConfirmOrder'} component={ConfirmOrder}/>
                <LogedRoutes path={'/ViewOrder/:orderId'} component={ViewOrder}/>
                <LogedRoutes path={'/ResetPassword'} component={ChangePassword}/>
                <Redirect exact from="/" to={'/Home'} />
            </Switch>
            <Footer />
        </div>
    );
};
export default AppRoutes;
