import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { SERVICES } from '../../Services/Services';
import { SHOPPINGCART } from '../../Services/ShoppingCart';
import { getBadgeNum } from '../../Store/Slices/badgeNumCounter';
import america from '../../Assets/americanexpress_1_color_card.svg';
import masterCard from '../../Assets/mastercard_color_card.svg';
import visa from '../../Assets/visa_1_color_card.svg';
import './Product.style.css';

export const Product = () => {
    const { idProduct } = useParams();
    const dispatch = useDispatch();
    const [product, setProduct] = useState({});

    const saveProduct = (e) => {
        // console.log('e ->', e);
        SHOPPINGCART.saveProduct(product);
        // console.log('my list ->', localStorage.getItem('SHOPPINGCART'))
        dispatch(getBadgeNum());
    }

    useEffect(() => {
        const fetchData = async () => {
            console.log('idProduct ->', idProduct);
            await SERVICES.showProduct(idProduct).then((res) => {
                console.log('res ->', res);
                setProduct(res);
                // localStorage.clear();
                // dispatch(getBadgeNum())
            }).catch((error) => {
                console.log('error ->', error);
            })
        }
        fetchData();
    }, [])

    return (
        <>
            <div className="card mb-3 container border-0">
                <div className="row g-0">
                    <div className="col-md-6">
                        <img
                            className='img-fluid rounded-start product-img'
                            src={product.img}
                            alt='product img'
                        />
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        <div className="card-body">
                            <h5 className="card-title fw-bold my-4">{product.name}</h5>
                            <p className="card-subtitle lh-1 text-break pdescription-product">{product.description}</p>
                            <p className="card-subtitle pb-2 pt-4 view-product-price">{'$' + Number(product.price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                            <p>Compra segura por:</p>
                            <div className='d-flex mt-2'>
                                <div className='me-3'>
                                    <img
                                        src={america}
                                        className='img-brands-viewproduct'
                                        alt=''
                                    />
                                </div>
                                <div className='me-3'>
                                    <img
                                        src={visa}
                                        className='img-brands-viewproduct'
                                        alt=''
                                    />
                                </div>
                                <div className='me-1'>
                                    <img
                                        src={masterCard}
                                        className='img-brands-viewproduct'
                                        alt=''
                                    />
                                </div>
                            </div>
                            <button onClick={(e) => saveProduct(e)} className='btn btn-outline-primary mt-4 btn-buy-product py-3 px-5'>
                                Agregar al carrito
                                <i className="bi bi-cart"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
