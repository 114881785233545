import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './Footer.style.css';

export const Footer = () => {
    
    const history = useHistory();

    
    return (
        <footer className={`footer-container-main mx-auto h-100 p-4 row row-cols-1 row-cols-sm-1 row-cols-md-3`}>
            
            <div>
                <p className='fw-bold fs-5 mb-2'>Politicas</p>
                <p onClick={(e) => {e.preventDefault(); history.push('/Warranty')}} className='p-footer-policies'>Politicas de Garantia</p>
                <p onClick={(e) => {e.preventDefault(); history.push('/Privacy')}} className='p-footer-policies'>Politicas de Privacidad</p>
                <p onClick={(e) => {e.preventDefault(); history.push('/Returns')}} className='p-footer-policies'>Politicas de Devolucion</p>
                <p onClick={(e) => {e.preventDefault(); history.push('/Shipment')}} className='p-footer-policies'>Politicas de Envio</p>
                <p onClick={(e) => {e.preventDefault(); history.push('/Services')}} className='p-footer-policies'>Politicas del Servicio</p>
            </div>
            <div>
                <p className='fw-bold fs-5 mb-2'>Contactanos</p>
            </div>
        </footer>
    )
}
