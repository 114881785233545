import React, { useEffect, useState } from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import { useHistory } from 'react-router-dom';
import { SERVICES } from '../../Services/Services';
import { OrdersList } from '../OrdersList/OrdersList';
import './Orders.style.css';
import { useDispatch } from 'react-redux';
import { logOut } from '../../Store/Profile/ProfileStatus';
import Swal from 'sweetalert2';

export const Orders = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [errorToast, setErrorToast] = useState(false);
    const [orders, setOrders] = useState([]);

    const viewError = () => {
        localStorage.removeItem('USER');
        dispatch(logOut());
        Swal.fire({title:'La sesion expiro', icon:'error', confirmButtonColor:'red'});
        history.push('/Home');
    }


    useEffect(() => {
        const fetchData = async () => {
            await SERVICES.getOrders().then((res) => {
                console.log('res ->', res);
                if(res.error === true) return viewError();
                setOrders(res.client_orders);
            }).catch((error) => {
                // console.log('error ->', error);
            });
        };
        fetchData();
    }, [])
    return (
        <div className='p-2 m-4 address-container'>
            <p className='fs-3 text-center my-2'>Lista de ordenes</p>
            {/* <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4'> */}
                {orders.map((item, index) => (
                    <OrdersList key={index} order={item} />
                ))}
            {/* </div> */}

        </div>
    )
}
