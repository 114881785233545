import React from "react";
import { Route, Redirect } from "react-router-dom";

const LogedRoutes = ({ component: Component, ...restOfProps }) => {
    const isAuthenticated = localStorage.getItem("USER");

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/LogIn" />
                )
            }
        />
    );
}

export default LogedRoutes;
