import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import './OrdersList.style.css';

export const OrdersList = ({ order }) => {
    
    const history = useHistory();
    
    return (
        <div onClick={(e) => {e.preventDefault(); history.push(`/ViewOrder/${order.id}`)}} className='d-flex border rounded p-2 mb-1 div-camp-orders'>
            <div>
                <p className='fw-bold id-order-orderlist'>Orden #{order.id}</p>
                <p>Total: ${Number(order.total).toFixed(2)}</p>
                <p>Metodo de pago: {order.payment_method}</p>
                <p className='text-muted'>Fecha: {moment(order.created_at).format('DD/MM/YYYY')}</p>
            </div>
        </div>
    )
}
