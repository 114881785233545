import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { SERVICES } from '../../../Services/Services';
import { logIn } from '../../../Store/Profile/ProfileStatus';
import './LogIn.style.css';

export const LogIn = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [email_pass, setEmail_pass] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorToast, setErrorToast] = useState(false);



    const forgotPass = async (e) => {
        e.preventDefault();
        if (email.trim() === "") return console.log('no escribio correo')
        await SERVICES.forgotPass(email_pass.trim()).then((res) => {
            console.log('res forgot ->', res);
        }).catch((error) => {
            console.log('error forgot ->', error);
        });
    }

    const initSession = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (
            email.trim() === "" ||
            pass.trim() === ""
        ) return console.log('llene ambos campos');
        const data = {
            "email": email,
            "password": pass
        };
        console.log('data cre ->', data)
        await SERVICES.login(data).then(async (res) => {
            console.log('response ->', res);
            if (!res.result) {
                setErrorToast(true);
                setIsLoading(false);
                setTimeout(() => {
                    setErrorToast(false);
                }, 6000);
            } else {
                localStorage.setItem('USER', JSON.stringify(res.user));
                dispatch(logIn(res.user));
                history.push('/Home');
            }

        }).catch((error) => {
            // console.log('error ->', error)
            setIsLoading(false);
        })

    }

    return (
        <div className='container div-login-container'>
            <div className='row '>
                <div className="col">
                    <p className='ptitle-login-form'>Iniciar sesion</p>
                    <div className="border p-4">
                        <div className="mb-3">
                            <p className='label-input-login-form'>Correo electronico</p>
                            <input
                                type={'email'}
                                placeholder='test@ejemplo.com'
                                className='border form-label p-2 w-100 input-login-form'
                                value={email}
                                onChange={({ target }) => setEmail(target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <p className='label-input-login-form'>Contraseña</p>
                            <input
                                type={'password'}
                                placeholder='********'
                                className='border form-label p-2 w-50 input-login-form'
                                value={pass}
                                onChange={({ target }) => setPass(target.value)}
                            />
                        </div>
                        <button disabled={isLoading} onClick={initSession} className="btn btn-primary btn-login-form">Iniciar sesion</button>
                        <div className='dropdown'>
                            <p className='pt-2 forgot-pass-p dropdown-toggle' type="button" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" >OLVIDE MI CONTRASEÑA</p>
                            <form className="dropdown-menu  p-4">
                                <div className="mb-3">
                                    <label htmlFor="exampleDropdownFormEmail2" className="form-label">Brindenos una direccion de correo electronica para contactarte</label>
                                    <input value={email_pass} onChange={({ target }) => setEmail_pass(target.value)} type="email" className="form-control" id="exampleDropdownFormEmail2" placeholder="email@example.com" />
                                </div>
                                <button onClick={forgotPass} type="submit" className="btn btn-primary">Enviar correo</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <p className='ptitle-create-form'>Eres nuevo?</p>
                    <div className="border p-4">
                        <p className='label-input-login-form'>Crea una cuenta con nosotros!</p>
                        <p className='desc-create-info'>Es rapido, facil y sencillo</p>
                        <button onClick={() => history.push('/CreateAccount')} className="btn btn-primary btn-login-form">
                            Crear cuenta
                        </button>
                    </div>
                </div>
                <ToastContainer position='bottom-end'>
                    <Toast bg='danger' show={errorToast} onClose={() => setErrorToast(false)}>
                        <Toast.Header>
                            <strong className="me-auto">Ups...</strong>
                        </Toast.Header>
                        <Toast.Body className='text-white'>Verifica que el correo y la contraseña esten bien escritos</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </div>
    )
}
