import axios from "axios";
import { URIS } from "./Uris";

const userValue = async () => {
    let user = await localStorage.getItem('USER');
    user = JSON.parse(user);
    return (user);
}

export const SERVICES = {
    async createAccount(data) {
        let user = await userValue();
        try {
            let response = await axios.post(`${URIS.users}/`, {
                "user": {
                    "email": data.email,
                    "name": data.name,
                    "lastname": data.lastname,
                    "phone": data.phone,
                    "avatar": "",
                    "password": data.password,
                    "password_confirmation": data.password_confirmation
                }
            });
            // console.log('resp ser ->', response)
            return response.data;
        } catch (error) {
            // console.log('error ->', error.response);
            // Sentry.Native.captureException(new Error(error.response))
            const resultError = { message: error.message, error: true, token: error.is_valid_token }
            return resultError;
        }
    },
    async updateAccount(data) {
        let user = await userValue();
        try {
            let response = await axios.patch(`${URIS.users}/current_user`, {
                "user": {
                    "email": data.email,
                    "name": data.name,
                    "lastname": data.lastname,
                    "phone": data.phone,
                    "avatar": data.avatar,
                    "password": data.password,
                    "password_confirmation": data.password_confirmation
                }
            }, {headers: {token: user.token}});
            // console.log('resp ser ->', response)
            return response.data;
        } catch ({message, }) {
            // console.log('error ->', message);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    
    async updatePassword(data) {
        let user = await userValue();
        try {
            let response = await axios.patch(`${URIS.users}/current_user`, {
                "user": {
                    "password": data.password,
                    "password_confirmation": data.password_confirmation
                }
            }, {headers: {token: user.token}});
            // console.log('resp ser ->', response)
            return response.data;
        } catch ({message, }) {
            // console.log('error ->', message);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async forgotPass(email) {
        let user = await userValue();
        try {
            let response = await axios.post(`${URIS.forgot_password}`, {
                "user":{
                    "email": email
                }
            },);
            // console.log('resp ser ->', response)
            return response.data;
        } catch ({message, }) {
            // console.log('error ->', message);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async login(data) {
        // let user = await userValue();
        console.log('dataa ->', data)
        try {
            let response = await axios.post(`${URIS.sessions}`, {
                "email": data.email,
                "password": data.password
            });
            return response.data;
        } catch (error) {
            // console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async logout() {
        let user = await userValue();
        try {
            let response = await axios.delete(`${URIS.sessions}/${user.token}`);
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async getCategories() {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.categories}`);
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async show_category(id) {
        console.log('id ->', id)
        let user = await userValue();
        try {
            // console.log(`${URIS.viewCategory}/${id}`)
            let response = await axios.get(`${URIS.viewCategory}/${id}`);
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async show_subCategorie(id) {
        console.log('id ->', id)
        let user = await userValue();
        try {
            // console.log(`${URIS.viewCategory}/${id}`)
            let response = await axios.get(`${URIS.viewSubCategory}/${id}`);
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async getProducts() {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.products}`);
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async getProductsNewest() {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.products_new}`);
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async showProduct(id) {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.showProduct}/${id}`);
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async searchProduct(text) {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.showProduct}/?&searchkey=${text}`);
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async getBanners() {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.banners}`);
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async getOrders() {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.orders}`, {headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            const resultError = { message: error.message, error: true}
            return resultError;
        }
    },
    async showOrders(id) {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.orders}/${id}`, {headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async getAddress() {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.addresses}`, {headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            const resultError = { message: error.message, error: true, }
            return resultError;
        }
    },
    async addAddress(data) {
        let user = await userValue();
        try {
            let response = await axios.post(`${URIS.addresses}`, data, {headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async updateAddress(data) {
        let user = await userValue();
        try {
            let response = await axios.put(`${URIS.addresses}/${data.address.id}`, data, {headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async deleteAddress(id) {
        let user = await userValue();
        try {
            let response = await axios.delete(`${URIS.addresses}/${id}`, {headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async getPaymenths() {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.payment_methods}`, {headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            const resultError = { message: error.message, error: true,  }
            return resultError;
        }
    },
    async addPaymenth(id) {
        let user = await userValue();
        try {
            let response = await axios.post(`${URIS.payment_methods}`, {
                "conektaTokenId": id
            },{headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async deletePaymenth(id) {
        let user = await userValue();
        console.log('user ->', user)
        try {
            let response = await axios.delete(`${URIS.payment_methods}/${id}`, {headers: {token: user.token}}, );
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
    async getShippingTypes() {
        let user = await userValue();
        try {
            let response = await axios.get(`${URIS.delivery_methods}`, {headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            const resultError = { message: error.message, error: true, token: error.is_valid_token }
            return resultError;
        }
    },
    async createOrder(data) {
        let user = await userValue();
        try {
            let response = await axios.post(`${URIS.orders}`, data, {headers: {token: user.token}});
            return response.data;
        } catch (error) {
            console.log('error ->', error);
            // Sentry.Native.captureException(new Error(error.response))
            // const resultError = { message: error.message, error: true, token: error.is_valid_token }
            // return resultError;
        }
    },
}