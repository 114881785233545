import React from 'react'
import { API_ENDPOINT_IMAGE } from '../../Services/Uris'

export const Carousel = ({ banners }) => {
    return (
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {banners.map((item, index) => (
                    <div key={index} className="carousel-item active">
                        <img src={`${API_ENDPOINT_IMAGE}${item.img}`} className="d-block w-100" alt='banner' />
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}
