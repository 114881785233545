import React, { useEffect, useState } from 'react';
import { SERVICES } from '../../Services/Services';
import { AddressCards } from './AddresCards';
import { useHistory } from 'react-router-dom';
import { AddressForm } from './AddressForm/AddressForm';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import { AddressFormUpdate } from './AddressFormUpdate/AddressFormUpdate';
import './Address.style.css';
import { useDispatch } from 'react-redux';
import { logOut } from '../../Store/Profile/ProfileStatus';

export const Address = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [errorToast, setErrorToast] = useState(false);
    const [address, setAddress] = useState([]);

    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const deleteAddress = async (id) => {
        await SERVICES.deleteAddress(id).then((res) => {
            // console.log('res ->', res);
            // setVisible(false);
            fetchData();
        }).catch((error) => {
            console.log('error ->', error)
        })
    }

    const viewError = () => {
        dispatch(logOut());
        localStorage.removeItem('USER');
        setErrorToast(true);
        setTimeout(() => {
            history.push('/Home');
            setErrorToast(false);
        }, 3000);
    }




    const fetchData = async () => {
        await SERVICES.getAddress().then((res) => {
            console.log('res ->', res);
            if (res.error === true) return viewError();
            setAddress(res);
        }).catch((error) => {
            console.log('error ->', error);
        })
    }

    useEffect(() => {
        fetchData();

    }, []);

    return (
        <div className="p-3 address-container">
            {address.length === 0 ?
                <>
                    <p className='text-center color-light'>No Tiene direcciones</p>
                    <p className='text-secondary text-center'>Crea una direccion para poder recibir tus productos</p>
                </>
                :
                <div className='row row-cols-1 row-cols-sm-3 row-cols-md-4 jlcc-rows'>
                    {address.map((item, index) => (
                        <AddressCards fetchData={fetchData} card={item} key={index} />
                    ))}
                </div>

            }
            {!errorToast &&
                <button className='btn btn-outline-primary' onClick={() => handleShow()}>
                    Agregar direccion <i className="bi bi-map"></i>
                </button>
            }

            <AddressForm fetchData={fetchData} handleClose={handleClose} show={show} />
            <ToastContainer position='bottom-end'>
                <Toast bg='danger' show={errorToast}>
                    <Toast.Header>
                        <strong className="me-auto">La sesion expiro</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>Inicie sesion nuevamente para proceder</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}
