import React from 'react';
import { useDispatch } from 'react-redux';
import { getBadgeNum } from '../../Store/Slices/badgeNumCounter';
import './CartProducts.style.css';

export const CartProducts = ({ product, setProducts, index, setTotal }) => {
    const dispatch = useDispatch();

    const updateCart = (quantity) => {
        // console.log('quantity ->', quantity);
        let productos = localStorage.getItem('SHOPPINGCART');
        productos = JSON.parse(productos);
        if (Number(quantity) === 0) {
            productos.splice(index, 1);
        } else {
            productos[index].quantity = Number(quantity);
        }
        // console.log('productos final ->', productos);
        localStorage.setItem('SHOPPINGCART', JSON.stringify(productos));
        setProducts(productos);
        let subTotal = 0;
        productos.map((item, index) => {
            return subTotal = subTotal + (item.quantity * item.price);
        });
        setTotal(subTotal);
        dispatch(getBadgeNum());

    }

    const deleteProduct = (e) => {
        e.preventDefault();
        let productos = localStorage.getItem('SHOPPINGCART');
        productos = JSON.parse(productos);
        productos.splice(index, 1);
        let subTotal = 0;
        productos.map((item, index) => {
            return subTotal = subTotal + (item.quantity * item.price)
        });
        localStorage.setItem('SHOPPINGCART', JSON.stringify(productos));
        setProducts(productos);
        setTotal(subTotal);
        dispatch(getBadgeNum());

    }

    return (
        <div className='row  align-items-center text-center'>
            <div className='col'>
                <img src={product.img} className='img-fluid w-50' alt='Product img' />
            </div>
            <div className='col'>
                <p>{product.name}</p>
            </div>
            <div className='col total-camp'>
                <p>${Number(product.price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
            </div>
            <div className='col text-center'>
                <div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button onClick={() => updateCart(Number(product.quantity - 1))} className="btn btn-outline-primary rounded-0">-</button>
                    <button disabled={true} className="btn btn-outline-primary rounded-0">{product.quantity}</button>
                    <button onClick={() => updateCart(Number(product.quantity + 1))} className="btn btn-outline-primary rounded-0">+</button>
                </div>
            </div>
            <div className='col total-camp price-text-color-cart'>
                <p>{'$' +  (Number(product.quantity * product.price).toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
            </div>
            <div className='col'>
                <i onClick={deleteProduct} className="bi bi-x-circle-fill delete-product-icon-cart"></i> 
            </div>
        </div>
    )
}
