import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Cards } from '../../Layouts/Cards';
import { SERVICES } from '../../Services/Services';
import './Search.style.css';

export const Search = () => {
    const history = useHistory();
    const [search, setSearch] = useState("");
    const [products, setProducts] = useState([]);
    let { searchText } = useParams();

    const pressedEnter = (key) => {
        if (key === 'Enter') return searchProduct();
    }

    const searchProduct = async () => {
        console.log('search ->', search);
        history.push(`/Search/${search}`);
    }

    useEffect(() => {
        const fetchData = async () => {
            console.log('busqueda ->', searchText);
            await SERVICES.searchProduct(searchText).then((res) => {
                console.log('res ->', res);
                setSearch(searchText);
                setProducts(res.products);
            }).catch((error) => {
                console.log('error ->', error)
            })
        }
        fetchData();
    }, [searchText])

    return (
        <>
            <div className='div-results'>
                <p className='p-title-results'>Los resultados para {search} son {products.length}:</p>
            </div>

            <div className='container text-center'>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 ">
                {products.map((item, index) => (
                    <div key={index} className="col">
                        <Cards product={item} />
                    </div>
                ))}
                </div>
                
            </div>
        </>
    )
}
