import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { SERVICES } from '../../../Services/Services';

export const AddressFormUpdate = ({ data, viewUpdateModal, setViewUpdateModal, fetchData }) => {
    const [street, setStreet] = useState(data.street);
    const [neighbordhood, setNeighbordhood] = useState(data.neighborhood);
    const [city, setCity] = useState(data.city);
    const [state, setState] = useState(data.state);
    const [country, setCountry] = useState(data.country);
    const [cp, setCp] = useState(data.postal_code);
    const [extNum, setExtNum] = useState(data.ext_num);
    const [intNum, setIntNum] = useState(data.int_num);

    const errorView = (title, message) => {
        // Alert.alert(`${title}`, `${message}`, [{ text: 'Entendido' }])
    }


    const updateAddress = async (e) => {
        e.preventDefault();
        if (
            street.trim() === "" ||
            neighbordhood.trim() === "" ||
            city.trim() === "" ||
            state.trim() === "" ||
            country.trim() === "" ||
            cp.trim() === "" ||
            extNum.trim() === ""
        ) return errorView('Complete el formulario', '');
        const info = {
            "address": {
                "street": street.trim(),
                "neighborhood": neighbordhood.trim(),
                "city": city.trim(),
                "state": state.trim(),
                "country": country.trim(),
                "postal_code": cp.trim(),
                "int_num": intNum.trim(),
                "ext_num": extNum.trim(),
                "id": data.id,
            }
        }
        // console.log('address ->', address)
        await SERVICES.updateAddress(info).then((res) => {
            console.log('res ->', res);
            setViewUpdateModal(false);
            fetchData();
            // navigation.goBack();
        }).catch((error) => {
            console.log('error ->', error)
        })
    }


    return (
        <Modal show={viewUpdateModal} onHide={() => setViewUpdateModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Informacion actual</Modal.Title>
            </Modal.Header>
            <div className='modal-boy p-3'>
                <form onSubmit={updateAddress}>
                    <label className='form-text ps-2'>Calle</label>
                    <input className='form-control' value={street} onChange={({ target }) => setStreet(target.value)} />
                    <label className='form-text ps-2'>Colonia</label>
                    <input className='form-control' value={neighbordhood} onChange={({ target }) => setNeighbordhood(target.value)} />
                    <label className='form-text ps-2'>Ciudad</label>
                    <input className='form-control' value={city} onChange={({ target }) => setCity(target.value)} />
                    <label className='form-text ps-2'>Estado</label>
                    <input className='form-control' value={state} onChange={({ target }) => setState(target.value)} />
                    <label className='form-text ps-2'>Pais</label>
                    <input className='form-control' value={country} onChange={({ target }) => setCountry(target.value)} />
                    <label className='form-text ps-2'>Codigo postal</label>
                    <input className='form-control' value={cp} onChange={({ target }) => setCp(target.value)} />
                    <label className='form-text ps-2'>Numerio exterior</label>
                    <input className='form-control' value={extNum} onChange={({ target }) => setExtNum(target.value)} />
                    <label className='form-text ps-2'>Numero interior</label>
                    <input className='form-control' value={intNum} onChange={({ target }) => setIntNum(target.value)} />
                </form>
            </div>
            <div className="modal-footer">
                <button className='btn btn-outline-secondary'>
                    cerrar
                </button>
                <button onClick={updateAddress} className='btn btn-outline-primary'>
                    Actualizar
                </button>
            </div>
        </Modal>
    )
}
