
export const SHOPPINGCART = {
    saveProduct(product) {
        let list = localStorage.getItem('SHOPPINGCART');
        // console.log('list ->', list);
        if (!list) {
            product.quantity = 1;
            localStorage.setItem('SHOPPINGCART', JSON.stringify([product]));
        } else {
            let test = JSON.parse(list);
            test = [...test];
            // console.log('test ->', test)
            let index = test.findIndex((value, index) => value.id === product.id);
            // console.log('index ->', index)
            if (index > -1) {
                // console.log('list[index].quantity ->', test[index].quantity)
                test[index].quantity = Number(test[index].quantity) + 1;
                // console.log('test ->', test)
                localStorage.setItem('SHOPPINGCART', JSON.stringify(test));
            } else {
                test.push(product);
                // console.log('test ->', test)
                localStorage.setItem('SHOPPINGCART', JSON.stringify(test));
            }
        }
    },
    addProduct(id) {
        // console.log('id ->', id)
        let list = [...JSON.parse(localStorage.getItem('SHOPPINGCART'))];
        // console.log('list ->', list);
        let index = list.findIndex(value => value.id === id);
        console.log('index ->', index)
        list[index].quantity = list[index].quantity + 1;
        localStorage.setItem('SHOPPINGCART', JSON.stringify(list));
        return list;
    },
    subtract(id) {
        // console.log('id ->', id)
        let list = [...JSON.parse(localStorage.getItem('SHOPPINGCART'))];
        // console.log('list ->', list);
        let index = list.findIndex(value => value.id === id);
        console.log('index ->', index)
        if (list[index].quantity === 1) {
            list.splice(index, 1);
        } else {
            list[index].quantity = list[index].quantity - 1;
        }
        localStorage.setItem('SHOPPINGCART', JSON.stringify(list));
        return list;

    },
    deleteProduct(id) {
        // console.log('id ->', id)
        let list = [...JSON.parse(localStorage.getItem('SHOPPINGCART'))];
        // console.log('list ->', list);
        let index = list.findIndex(value => value.id === id);
        // console.log('index ->', index);
        list.splice(index, 1);
        console.log('lis new->', list)
        localStorage.setItem('SHOPPINGCART', JSON.stringify(list));
        return list;
    },

    getBadge() {
        let cart = localStorage.getItem('SHOPPINGCART');
        if (cart) {
            cart = JSON.parse(cart).length
            // console.log("CART -->", cart)
        } else {
            cart = 0
        }
        return cart
    }
}