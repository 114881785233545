import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import { AddressForm } from '../../../Layouts/Address/AddressForm/AddressForm';
import { PaymenthModal } from '../../../Layouts/Paymenths/PaymenthModal/PaymenthModal';
import { SERVICES } from '../../../Services/Services';
import { getBadgeNum } from '../../../Store/Slices/badgeNumCounter';
import './ConfirmOrder.style.css';
import { logOut } from '../../../Store/Profile/ProfileStatus';
import Swal from 'sweetalert2';

export const ConfirmOrder = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [address, setAddress] = useState([]);
    const [addressSelect, setAddressSelect] = useState({});
    const [paymenths, setPaymenths] = useState([]);
    const [paymenthSelect, setPaymenthSelect] = useState({});
    const [shipping, setShipping] = useState([]);
    const [shippingSelect, setShippingSelect] = useState({});
    const [shippingCost, setShippingCost] = useState(0);
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [modalCard, setModalCard] = useState(false);
    const [modalAddress, setModalAddress] = useState(false);
    const [errorToast, setErrorToast] = useState(false);
    const [messageToast, setMessageToast] = useState("");
    const [completed, setCompleted] = useState(false);
    const [errorInit, setErrorInit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [subTotal, setSubTotal] = useState(0);

    const viewError = () => {
        localStorage.removeItem('USER');
        dispatch(logOut());
        setErrorInit(true);
        setTimeout(() => {
            history.push('/Home');
            setErrorInit(false);
        }, 3000);
    }


    const createOrder = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        let ids = [];
        products.map((item) => ids.push({ "product_id": item.id }))
        const data = {
            "client_order": {
                "delivery_method_id": shippingSelect.id,
                "address_id": addressSelect.id,
                "card_id": paymenthSelect.id,
                "payment_method": "TARJETA/ONLINE",
                "order_type": 0,
                "line_products": ids
            }
        }
        // console.log('data ->', data);
        await SERVICES.createOrder(data).then(async (res) => {
            // console.log('res ->', res);
            if (res.result === false) {
                // Alert.alert('Ups...', `${res.message}`, [{ text: 'Entendido' }]);
                Swal.fire({title: 'Error', text:res.message, icon:'error', confirmButtonColor:'red'});

                // setMessageToast(res.message);
                // setErrorToast(true);
                // setTimeout(() => {
                //     setErrorToast(false);
                // }, 6000);
                setIsLoading(false);
                // console.log('res ->', res);
            } else {
                Swal.fire({title: 'Orden completada', icon:'success', confirmButtonColor:'#00afef'});
                localStorage.removeItem('SHOPPINGCART');
                history.push(`/ViewOrder/${res.id}`);
                dispatch(getBadgeNum());
                // await AsyncStorage.removeItem('SHOPPINGCART');
                // navigation.navigate('HomeTab', { screen: 'Home' });
                // navigation.navigate('ProfileTab', { screen: 'OrdersIndex' });
            }

        }).catch((error) => {
            console.log('error ->', error)
            setIsLoading(false);
        })
    }

    const fetchData = async () => {
        let productos = localStorage.getItem('SHOPPINGCART');
        productos = JSON.parse(productos);
        // console.log('productos ->', productos);
        if (!productos || productos.length === 0) {
            history.push('/Home');
        } else {
            setProducts(productos);
            await SERVICES.getPaymenths().then((res) => {
                // console.log('res ->', res);
                if (res.error === true) return viewError();
                setPaymenths(res.credit_cards);
                setPaymenthSelect(res.credit_cards[0] ? res.credit_cards[0] : {});
            }).catch((error) => {
                // console.log('error ->', error);
            });
            await SERVICES.getAddress().then((res) => {
                // console.log('address ->', res)
                if (res.error === true) return viewError();
                setAddress(res);
                setAddressSelect(res[0] ? res[0] : {});
            }).catch((error) => {
                // console.log('error address ->', error);
            })
            await SERVICES.getShippingTypes().then((res) => {
                // console.log('shippings ->', res);
                if (res.error === true) return viewError();
                setShipping(res);
                setShippingSelect(res[0]);
                setShippingCost(res[0].cost)
            }).catch((error) => {
                // console.log('error shipping ->', error);
            })
            let subTotal = 0;
            productos.map((item, index) => {
                return subTotal = subTotal + (item.quantity * item.price);
            });
            setTotal(subTotal);
        }

    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <div className='row row-cols-1 row-cols-sm-1 row-cols-md-2 m-4 address-container'>
            <div className='col'>
                <div className='p-2'>
                    <p className='fw-bold text-center'>Selecciona un metodo de pago</p>
                    {paymenths.length === 0 ?
                        <>
                            <p className='text-center'>No cuenta con metodos de pago</p>
                        </>
                        :
                        <div className=''>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                defaultValue={paymenthSelect}
                                onChange={(e) => { setPaymenthSelect(paymenths.find(value => value.id === e.target.value)) }}
                            >
                                {paymenths.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}, ****{item.last4}</option>
                                ))}
                            </select>

                        </div>
                    }
                    <div className='mt-2 d-flex justify-content-center'>
                        <button onClick={(e) => { e.preventDefault(); setModalCard(true) }} className='btn btn-outline-primary'>
                            Agregar metodo de pago
                        </button>
                    </div>
                </div>
                <div className='p-2'>
                    <p className='fw-bold text-center'>Seleccione una direccion</p>
                    {address.length === 0 ?
                        <>
                            <p className='text-center'>No cuenta con direcciones</p>
                        </>
                        :
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            defaultValue={addressSelect}
                            onChange={(e) => { setAddressSelect(address.find(value => value.id === Number(e.target.value))) }}
                        >
                            {address.map((item, index) => (
                                <option key={index} value={item.id}>{item.street}, {item.neighborhood} #{item.postal_code}, {item.city}, {item.state}, num. exterior: {item.ext_num}, num. interior: {item.int_num}</option>
                            ))}
                        </select>

                    }
                    <div className='mt-2 d-flex justify-content-center'>
                        <button onClick={(e) => { e.preventDefault(); setModalAddress(true) }} className='btn btn-outline-primary'>
                            Agregar direccion
                        </button>
                    </div>

                </div>
                <div className='p-2 mb-4'>
                    <p className='fw-bold text-center'>Metodo de envio</p>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        defaultValue={shippingSelect}
                        onChange={(e) => { setShippingSelect(shipping.find(value => value.id === Number(e.target.value))) }}
                    >
                        {shipping.map((item, index) => (
                            <option key={index} value={item.id}>{item.name}, {item.description}, ${Number(item.cost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</option>
                        ))}
                    </select>
                    {/* <div>
                        {shipping.map((item, index) => (
                            <div key={index} onClick={(e) => { e.preventDefault(); setShippingSelect(item); setShippingCost(Number(item.cost)) }} className='border mb-2 rounded p-2 d-flex'>
                                <div className='me-auto'>
                                    <p>{item.name}</p>
                                    <p>{item.description}</p>
                                    <p>${Number(item.cost).toFixed(2)}</p>
                                </div>
                                {item.id === shippingSelect.id &&
                                    <div className='pe-3 d-flex align-items-center'>
                                        <i className="bi bi-patch-check-fill"></i>
                                    </div>
                                }
                            </div>

                        ))}
                    </div> */}
                </div>
            </div>
            <div className='col'>

                <div className='p-2 bg-light rounded'>
                    <p className='text-center'>Informacion final</p>
                    <div className='d-flex'>
                        <p className='me-auto'>Direccion</p>
                        <p className='address-answer'>{JSON.stringify(addressSelect) === '{}' ? 'Sin seleccionar'
                            :
                            `${addressSelect.street}, ${addressSelect.neighborhood} ${addressSelect.postal_code}, ${addressSelect.city}, 
                        ${addressSelect.state}, num. exterior: ${addressSelect.ext_num}, num. interior: ${addressSelect.int_num}`}
                        </p>
                    </div>
                    <div className='d-flex'>
                        <p className='me-auto'>Tarjeta:</p>
                        <p className='address-answer'>{JSON.stringify(paymenthSelect) === '{}' ? 'Sin seleccionar'
                            :
                            `**** ${paymenthSelect.last4}`}
                        </p>
                    </div>
                    <div className='d-flex'>
                        <p className='me-auto'>Metodo de entrega:</p>
                        <p className='address-answer'>{JSON.stringify(shippingSelect) === '{}' ? 'Sin seleccionar'
                            :
                            `${shippingSelect.name}`}
                        </p>
                    </div>
                    <div className='d-flex'>
                        <p className='me-auto'>Costo de envio:</p>
                        <p className='address-answer'>{JSON.stringify(shippingSelect) === '{}' ? 'Sin seleccionar'
                            :
                            `$${Number(shippingSelect.cost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
                        </p>
                    </div>
                    <div className='d-flex'>
                        <p className='me-auto'>Subtotal:</p>
                        <p className='address-answer'>${total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                    </div>
                    <div className='d-flex'>
                        <p className='me-auto'>Total a pagar:</p>
                        <p className='address-answer'>${Number(total + (JSON.stringify(shippingSelect) === '{}' ? 0 : shippingCost)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                    </div>
                    <button onClick={createOrder} disabled={JSON.stringify(shippingSelect) === '{}' || JSON.stringify(addressSelect) === '{}' || JSON.stringify(paymenthSelect) === '{}' || isLoading === true ? true : false} className='btn btn-outline-primary w-100 my-4'>
                        Finalizar compra
                    </button>
                    {JSON.stringify(shippingSelect) === '{}' || JSON.stringify(addressSelect) === '{}' || JSON.stringify(paymenthSelect) === '{}' ?
                        <p>Debes seleccionar los campos necesarios</p>
                        : null
                    }
                </div>

            </div>
            <PaymenthModal viewModalPaymenth={modalCard} setViewModalPaymenth={setModalCard} fetchData={fetchData} />
            <AddressForm fetchData={fetchData} handleClose={setModalAddress} show={modalAddress} />
            {/* <ToastContainer position='bottom-end'>
                <Toast bg='danger' show={errorToast} onClose={() => setErrorToast(false)}>
                    <Toast.Header>
                        <strong className="me-auto">Ups...</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>{messageToast}</Toast.Body>
                </Toast>
            </ToastContainer>
            <ToastContainer position='bottom-end'>
                <Toast bg='info' show={completed} onClose={() => setCompleted(false)}>
                    <Toast.Header>
                        <strong className="me-auto">Muy bien!</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>Se concreto tu compra</Toast.Body>
                </Toast>
            </ToastContainer>
            <ToastContainer position='bottom-end'>
                <Toast bg='danger' show={errorInit}>
                    <Toast.Header>
                        <strong className="me-auto">La sesion expiro</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>Inicie sesion nuevamente para proceder</Toast.Body>
                </Toast>
            </ToastContainer> */}
        </div>
    )
}
