import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import { SERVICES } from '../../Services/Services';
import { PaymenthModal } from '../Paymenths/PaymenthModal/PaymenthModal';
import { PaymenthListCard } from './PaymenthListCard/PaymenthListCard';
import { useDispatch } from 'react-redux';
import { logOut } from '../../Store/Profile/ProfileStatus';

export const PaymenthsCards = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [paymenths, setPaymenths] = useState([]);
    const [viewModalPaymenth, setViewModalPaymenth] = useState(false);
    const [errorToast, setErrorToast] = useState(false);

    const viewError = () => {
        setErrorToast(true);
        localStorage.removeItem('USER');
        dispatch(logOut());
        setTimeout(() => {
            history.push('/Home');
            setErrorToast(false);
        }, 3000);
    }

    const fetchData = async () => {
        await SERVICES.getPaymenths().then((res) => {
            console.log('res ->', res);
            if (res.error === true) return viewError();
            setPaymenths(res.credit_cards)
        }).catch((error) => {
            // console.log('error ->', error);
        })
    };
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className='p-3 address-container'>
            {!errorToast &&
                <button onClick={(e) => { e.preventDefault(); setViewModalPaymenth(true) }} className='btn btn-outline-primary mb-2'>
                    Agregar metodo de pago
                </button>
            }
            {paymenths.length === 0 ?
                <div className='text-center p-3 fs-5'>
                    <p>No cuenta con metodos de pago aun.</p>
                </div>
                :
                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4'>
                    {paymenths.map((item, index) => (
                        <PaymenthListCard fetchData={fetchData} index={index} key={index} card={item} />
                    ))}
                </div>
            }

            <PaymenthModal viewModalPaymenth={viewModalPaymenth} setViewModalPaymenth={setViewModalPaymenth} fetchData={fetchData} />
            <ToastContainer position='bottom-end'>
                <Toast bg='danger' show={errorToast}>
                    <Toast.Header>
                        <strong className="me-auto">La sesion expiro</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>Inicie sesion nuevamente para proceder</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}
