import { configureStore } from '@reduxjs/toolkit';
import { authSession } from './Profile/ProfileStatus';
import { counterSlice } from './Slices/badgeNumCounter';

export const store = configureStore({
    reducer: {
        counter: counterSlice.reducer,
        authSession: authSession.reducer
    },
})
