import React, { useEffect, useState } from 'react'
import Base64 from '../../../Services/Base64';
import { CONEKTA_KEY } from '../../../Services/Uris';

export const PaymenthCards = () => {
    
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async() => {
        let dataRequest = {
            "checkout": {
                "returns_control_on": "Token"
            }
        }
        await fetch('https://api.conekta.io/tokens',{
            method: 'Post',
            body:JSON.stringify(dataRequest),
            headers:{
                Accept: 'application/vnd.conekta-v2.0.0+json',
                'Content-type': 'Content-Type: application/json',
                Authorization: `Basic ${Base64.btoa(`${CONEKTA_KEY}`)}`
            }
        }).then((res) => {
            console.log('res ->', res);
        }).catch((error) => {
            console.log('error ->', error);
        })
    }
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div>PaymenthCards</div>
    )
}
