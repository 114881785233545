import React, { useEffect, useRef, useState } from 'react';
import { FaTimes, FaBars } from 'react-icons/fa'
import Logo from '../../Assets/images/smart-depot.png'
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './Navbar.style.css'
import { SERVICES } from '../../Services/Services';
import { logOut } from '../../Store/Profile/ProfileStatus';

export const Navbar = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { counter } = useSelector((state) => state.counter);
    const { user } = useSelector((state) => state.authSession);
    // const [user, setUser] = useState(null);
    const [text, setText] = useState("");
    const [categories, setCategories] = useState([]);

    const searching = (e) => {
        e.preventDefault();
        if (text.trim() !== "") {
            history.push(`/Search/${text.trim()}`);
        } else {
            return 0;
        }
    }
    const pressedEnter = (key, e) => {
        if (key === 'Enter') return searching(e);
    }

    const closeSession = async () => {
        // e.preventDefault();
        await SERVICES.logout().then(async (res) => {
            // console.log('res ->', res);
            localStorage.removeItem('USER');
            dispatch(logOut());
            history.push('/Home');
        }).catch((error) => {
            console.log('error ->', error);
        });
    }



    useEffect(() => {
        const fetchData = async () => {
            await SERVICES.getCategories().then(async (res) => {
                let final = [];
                console.log('res ->', res);
                // for (let i = 0; i < res.length; i++) {
                //     if (res[i].name === "CELULARES" || res[i].name === "AUDIFONOS " || res[i].name === "ACCESORIOS ") {
                //         final.push(res[i]);
                //     }

                // };
                
                // console.log('final ->', final)
                setCategories(res);
            }).catch((error) => {
            });
        }
        fetchData();
    }, [])


    return (
        <nav className="navbar navbar-expand-lg bg-white border-bottom nav-container ">
            <div className="container-fluid">
                <a className="navbar-brand" href="/Home">
                    <img
                        className='nav-main-icon'
                        src={Logo}
                        alt='SmartDepotIcon'
                    />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mb-2 jlcc-gap">

                        {categories.map((item, index) => (
                            <li key={index} className="nav-item d-flex justify-content-center">
                                <a className="btn-nav" href={`/Categories/${item.id}`}>{item.name}</a>
                            </li>
                        ))}
                        <div className="dropdown nav-item">
                            {/* <a className="btn-nav dropdown-toggle a-dropdown-navbar" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Politicas
                            </a> */}
                            <ul className="dropdown-menu ">
                                <li><a className="dropdown-item item-drop-navbar a-dropdown-navbar" href="/Warranty">Garantias</a></li>
                                <li><a className="dropdown-item item-drop-navbar a-dropdown-navbar" href="/MyAddress">Privacidad</a></li>
                                <li><a className="dropdown-item item-drop-navbar a-dropdown-navbar" href="/MyPaymenths">Devolucion</a></li>
                                <li><a className="dropdown-item item-drop-navbar a-dropdown-navbar" href="/MyOrders">Envio</a></li>
                                <li><a className="dropdown-item item-drop-navbar a-dropdown-navbar" href="/MyOrders">Servicio</a></li>
                            </ul>
                        </div>
                    </ul>
                    <form className="d-flex w-100 justo-te-amo" role="search">
                        <input
                            className="form-control me-2 ms-4"
                            type="search"
                            placeholder="Buscar..."
                            aria-label="Search"
                            onChange={({ target }) => setText(target.value)}
                            onKeyDown={(e) => pressedEnter(e.code, e)}
                        />
                        <button onClick={searching} className="btn btn-primary" >Buscar</button>
                        <div className="jlccseparator">
                            <i onClick={(e) => { e.preventDefault(); history.push('/Cart') }} className="bi bi-bag icon-bag-navbar cart-icons"></i>
                            <span onClick={(e) => { e.preventDefault(); history.push('/Cart') }} className="m-2 translate-middle badge rounded-pill bg-danger cart-icons">
                                {counter}
                            </span>
                        </div>
                        {JSON.stringify(user) !== '{}' ?
                            <div className="dropdown nav-item navbar-user">
                                <a className="btn-nav dropdown-toggle a-dropdown-navbar" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {user.name} {user.lastname}
                                </a>
                                <ul className="dropdown-menu ">
                                    <li><a className="dropdown-item item-drop-navbar a-dropdown-navbar" href="/MyProfile">Mi perfil <i className="bi bi-person"></i></a></li>
                                    <li><a className="dropdown-item item-drop-navbar a-dropdown-navbar" href="/MyAddress">Mis direcciones <i className="bi bi-map"></i></a></li>
                                    <li><a className="dropdown-item item-drop-navbar a-dropdown-navbar" href="/MyPaymenths">Mis tarjetas <i className="bi bi-credit-card"></i></a></li>
                                    <li><a className="dropdown-item item-drop-navbar a-dropdown-navbar" href="/MyOrders">Mis ordenes <i className="bi bi-ui-checks"></i></a></li>
                                    <li className="dropdown-item item-drop-navbar a-dropdown-navbar" onClick={() => { closeSession() }}>Cerrar sesion <i className="bi bi-box-arrow-left"></i></li>
                                </ul>
                            </div>
                            :
                            <div className="nav-item navbar-user">
                                <a className="btn-nav" href="/LogIn">Ingresar</a>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </nav>
    )
}
