//PROD SMARTDEPOT
export const API_ENDPOINT = 'https://app.smartdepotmx.com/api/online_shop';
export const API_ENDPOINT_IMAGE = 'https:';
export const CONEKTA_KEY = 'key_NG2MfrkNMiOY5tTaowqXOCH'

//QA SMARTDEPOT
// export const API_ENDPOINT = 'http://167.172.147.169:89/api/online_shop';
// export const API_ENDPOINT_IMAGE = 'http://167.172.147.169:89';
// export const CONEKTA_KEY = 'key_NG2MfrkNMiOY5tTaowqXOCH'
// export const API_ENDPOINTIMAGE = 'http://167.172.147.169:89/api/';

export const URIS = {
    categories: `${API_ENDPOINT}/categories?page=1&per_page=100`,
    viewCategory: `${API_ENDPOINT}/categories`,
    viewSubCategory: `${API_ENDPOINT}/sub_categories`,
    products:`${API_ENDPOINT}/products?&page=1&per_page=10&order_by=sales_count&order_type=asc'`,
    products_new:`${API_ENDPOINT}/products?&page=1&per_page=20&created_at=asc'`,
    banners: `${API_ENDPOINT}/banners`,
    showProduct: `${API_ENDPOINT}/products`,
    users: `${API_ENDPOINT}/users`,
    sessions: `${API_ENDPOINT}/sessions`,
    addresses: `${API_ENDPOINT}/addresses`,
    payment_methods: `${API_ENDPOINT}/payment_methods`,
    delivery_methods: `${API_ENDPOINT}/delivery_methods`,
    orders: `${API_ENDPOINT}/orders`,
    forgot_password: `${API_ENDPOINT}/forgot_password`,


}
