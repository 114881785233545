import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import { SERVICES } from '../../Services/Services';
import { API_ENDPOINT_IMAGE } from '../../Services/Uris';
import { logIn, logOut } from '../../Store/Profile/ProfileStatus';
import './UpdateAccountForm.style.css';

export const UpdateAccountForm = () => {
    const { user } = useSelector((state) => state.authSession);
    const history = useHistory();
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [imgb64, setImgb64] = useState("");
    const [token, setToken] = useState("");
    const [errorToast, setErrorToast] = useState(false);

    const viewError = () => {
        localStorage.removeItem('USER');
        dispatch(logOut());
        setErrorToast(true);
        setTimeout(() => {
            history.push('/Home');
            setErrorToast(false);
        }, 3000);
    }

    const inputFile = useRef(null);


    const handleUpdate = (e) => {
        e.preventDefault();
    }

    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');


    const validateFile = (e) => {
        console.log('e ->', e)
        const validFileFormats = ["image/png", "image/jpeg"];
        const file = e.target.files[0];
        const reader = new FileReader();
        const img = new Image()

        // validate file type
        if (file === undefined) return console.log("no file uploaded");
        if (!validFileFormats.includes(file.type))
            return console.log("file format not supported");

        // convert to base64 an set pp icon badge
        reader.readAsDataURL(file);


        // image validate res
        reader.onload = () => {
            img.src = reader.result

            img.onload = () => {
                console.log('reader ->', reader);
                const base64 = getBase64StringFromDataURL(reader.result);
                console.log('base ->', base64);
                setImgUrl(reader.result);
                setImgb64(base64);
            }
        };
    };

    const closeSession = async (e) => {
        e.preventDefault();
        await SERVICES.logout().then(async (res) => {
            // console.log('res ->', res);
            localStorage.removeItem('USER');
            dispatch(logOut());
            history.push('/Home');
        }).catch((error) => {
            console.log('error ->', error);
        });
    }
    const errorView = (title, message) => {
        // Alert.alert(`${title}`, `${message}`, [{ text: 'Entendido' }]);
        // setIsLoading(false);
    }

    const updateAccount = async () => {
        console.log('token ->', token)
        if (
            name.trim() === "" ||
            lastname.trim() === "" ||
            email.trim() === "" ||
            phone.trim() === ""
        ) return errorView('Complete el formulario', '');
        if (phone.length !== 10) return errorView('El telefono debe ser de 10 caracteres unicamente');
        const data = {
            "user": {
                "email": email.trim(),
                "name": name.trim(),
                "lastname": lastname.trim(),
                "phone": phone.trim(),
                "avatar": imgb64 !== "" ? imgb64 : imgUrl,
                "token": token
            }
        };
        await SERVICES.updateAccount(data.user).then(async (res) => {
            console.log('response create ->', res);
            if (!res.result) return errorView('Algo salio mal', ``);
            res.user.token = token;
            localStorage.setItem('USER', JSON.stringify(res.user));
            dispatch(logIn(res.user));
            // navigation.goBack();
        }).catch((error) => {
            console.log('error ->', error)
            // setIsLoading(false);
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            let response = await SERVICES.getAddress();
            console.log('response up->', response)
            if (response.error === true) return viewError();
            let user = JSON.parse(localStorage.getItem("USER"));
            console.log('usuario form ->', user)
            setName(user.name);
            setLastname(user.lastname);
            setPhone(user.phone);
            setEmail(user.email);
            setImgUrl(`${API_ENDPOINT_IMAGE}${user.avatar}`);
            setToken(user.token);
        }
        fetchData();
    }, [])



    return (
        <div className="p-5">
            {!errorToast &&
                <>
                    <div className="image-container mb-4">
                        <img
                            src={imgUrl}
                            alt='User img'
                            className='img-profile-user'

                        />
                    </div>
                    <div className="change-image-profile mb-4">
                        <input
                            type="file"
                            name="photo"
                            ref={inputFile}
                            onChange={validateFile}
                            hidden
                        />
                        <button
                            className="btn btn-primary "
                            onClick={() => inputFile.current.click()}
                        >
                            Cambiar imagen de perfil
                        </button>
                    </div>
                    <form onSubmit={handleUpdate} className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="inputEmail4" className="form-label">Nombre</label>
                            <input
                                type={'text'}
                                className="form-control"
                                id="inputEmail4"
                                value={name}
                                onChange={({ target }) => setName(target.value)}
                            />

                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputPassword4" className="form-label">Apellido</label>
                            <input
                                type={'text'}
                                className="form-control"
                                id="inputPassword4"
                                value={lastname}
                                onChange={({ target }) => setLastname(target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputAddress" className="form-label">Correo electronico</label>
                            <input
                                type={'email'}
                                className="form-control"
                                id="inputAddress"
                                value={email}
                                onChange={({ target }) => setEmail(target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputAddress" className="form-label">Numero Celular</label>
                            <input
                                type={'number'}
                                className="form-control"
                                id="inputAddress"
                                value={phone}
                                onChange={({ target }) => setPhone(target.value)}
                            />
                        </div>
                        <div className="col-12 ">
                            <button onClick={(e) => { e.preventDefault(); updateAccount(); }} className="btn btn-outline-primary m-1">Actualizar datos <i className="bi bi-tools"></i></button>
                            <button onClick={(e) => { e.preventDefault(); history.push('/ResetPassword') }} className="btn btn-outline-primary btn-change-pass m-1">Cambiar contraseña <i className="bi bi-lock-fill"></i></button>
                            {/* <button onClick={closeSession} className="btn btn-outline-danger btn-change-pass">Cerrar sesion <i className="bi bi-person-fill-exclamation"></i></button> */}
                        </div>
                    </form>
                </>
            }
            <ToastContainer position='bottom-end'>
                <Toast bg='danger' show={errorToast}>
                    <Toast.Header>
                        <strong className="me-auto">La sesion expiro</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>Inicie sesion nuevamente para proceder</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}
