import { createSlice } from '@reduxjs/toolkit';

export const initSession = (usuario) => {
    
    // console.log('entro')
    return async(dispatch, getState) => {
        dispatch(logIn({user: usuario}));
    }
}



export const authSession = createSlice({
    name: 'ProfileStatus',
    initialState:  {
        user: {}
    },
    reducers: {
        logOut: (state, action) => {
            state.user = {};
        },
        logIn: (state, action) => {
            // console.log('hizo el login')
            // console.log('action ->', action)
            state.user = action.payload
        }
    },
})

export const {logOut, logIn} = authSession.actions;