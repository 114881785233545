import React from 'react';
import { Address } from '../../../Layouts/Address';
import { SidebarProfile } from '../../../Layouts/SidebarProfile';
import './MyAddress.style.css';

export const MyAddress = () => {
    return (
        <Address />
    )
}
