import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import { Cards } from '../../Layouts/Cards';
import { Carousel } from '../../Layouts/Carousel';
import { SERVICES } from '../../Services/Services';
import { API_ENDPOINT_IMAGE } from '../../Services/Uris';
import './Home.style.css';

export const Home = () => {
    const [products, setProducts] = useState([]);
    const [newest, setNewest] = useState([]);
    const [banners, setBanners] = useState([]);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    useEffect(() => {
        const fetchData = async () => {
            await SERVICES.getProducts().then((res) => {
                // console.log('productos ->', res);
                setProducts(res.products);

            }).catch((error) => {
                // console.log('error ->', error);
            });
            await SERVICES.getBanners().then((res) => {
                // console.log('res banner ->', res);
                setBanners(res)
            }).catch((error) => {

            })
            await SERVICES.getProductsNewest().then((res) => {
                console.log('res new ->', res);
                setNewest(res.products);
                // setBanners(res)
            }).catch((error) => {

            })
        }
        fetchData();
    }, [])
    return (
        <>
            <Carousel banners={banners} />
            <p className='lomasvendido-title'>LO MAS VENDIDO</p>
            <div className='container'>
                <Slider autoplay={true} {...settings}>
                    {products.map((item, index) => (
                        <Cards product={item} key={index} />
                    ))}
                </Slider>
            </div>
            <p className='lomasvendido-title'>LO MAS NUEVO</p>
            <div className='container'>
                <Slider autoplay={true} {...settings}>
                    {newest.map((item, index) => (
                        <Cards product={item} key={index} />
                    ))}
                </Slider>
            </div>
        </>
    )
}
