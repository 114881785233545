import { createSlice } from '@reduxjs/toolkit';
import { SHOPPINGCART } from '../../Services/ShoppingCart';

export const getBadgeNum = () => {
    
    // console.log('entro')
    return async(dispatch, getState) => {
        let num = SHOPPINGCART.getBadge();

        // console.log('numerito ->', num)
        dispatch(updateBadge({num: num}));
    }
}

export const counterSlice = createSlice({
    name: 'counter',
    initialState:  {
        counter: 0
    },
    reducers: {
        updateBadge: (state, action) => {
            // console.log('action ->', action)
            state.counter = action.payload.num;
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateBadge } = counterSlice.actions

// export default counterSlice.reducer