import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Base64 from '../../../Services/Base64';
import { SERVICES } from '../../../Services/Services';
import { CONEKTA_KEY } from '../../../Services/Uris';

export const PaymenthModal = ({ viewModalPaymenth, setViewModalPaymenth, fetchData }) => {
        const [number, setNumber] = useState("");
        const [name, setName] = useState("");
        const [exp_month, setExp_month] = useState("");
        const [exp_year, setExp_year] = useState("");
        const [cvc, setCvc] = useState("");
        const [isLoading, setIsLoading] = useState(false);

        const errorView = (title, message) => {
            console.log('error ->', title, message)
            // Alert.alert(`${title}`, `${message}`, [{ text: 'Entendido' }]);
            setIsLoading(false);
        }

        const createPay = async () => {
            setIsLoading(true);
            const year = new Date().getFullYear();
            const month = new Date().getMonth();
            if (
                exp_year === "" ||
                number === "" ||
                exp_month === "" ||
                name === "" ||
                cvc === ""
            ) return errorView('Complete el formulario', '');
            if (exp_year <= year && exp_month <= month) return errorView('Su tarjeta ya esta caducada', 'Ingrese una tarjeta valida');
            // console.log('number ->', number.substring(0, 2))
            if (number.substring(0, 2) === "37") {
                console.log('es american express');
                if (
                    number.length < 15 || number.length > 15 ||
                    exp_year.length < 2 || exp_year.length > 4 ||
                    exp_month.length < 1 || exp_month.length > 2 ||
                    cvc.length < 4 || cvc.length > 4
                ) return errorView('Error en formulario', 'verifique que los datos esten escritos correctamente');
                const card = {
                    "number": number,
                    "name": name,
                    "exp_year": exp_year,
                    "exp_month": exp_month,
                    "cvc": cvc,
                }
                try {
                    const tokenRes = await fetch('https://api.conekta.io/tokens', {
                        method: 'POST',
                        body: JSON.stringify({ card }),
                        headers: {
                            Accept: 'application/vnd.conekta-v2.0.0+json',
                            'Content-Type': 'application/json',
                            Authorization: `Basic ${Base64.btoa(`${CONEKTA_KEY}:`)}`,
                            'Accept-Language': 'es', // TODO: Change this to get language from translation
                            'Conekta-Client-User-Agent': JSON.stringify({
                                agent: 'Conekta iOS SDK',
                            }),
                        },
                    });
                    const token = await tokenRes.json();
                    const response = await SERVICES.addPaymenth(token.id);
                    if (!response) return errorView('Error al crear', 'Intentelo mas tarde',);
                    setViewModalPaymenth(false);
                    fetchData();
                    setIsLoading(false);
                    // navigation.goBack();
                } catch (error) {
                    setIsLoading(false);
                }
            } else {
                if (
                    number.length < 16 || number.length > 16 ||
                    exp_year.length < 2 || exp_year.length > 4 ||
                    exp_month.length < 1 || exp_month.length > 2 ||
                    cvc.length < 3 || cvc.length > 3
                ) return errorView('Error en formulario', 'verifique que los datos esten escritos correctamente')
                const card = {
                    "number": number,
                    "name": name,
                    "exp_year": exp_year,
                    "exp_month": exp_month,
                    "cvc": cvc,
                }
                try {
                    const tokenRes = await fetch('https://api.conekta.io/tokens', {
                        method: 'POST',
                        body: JSON.stringify({ card }),
                        headers: {
                            Accept: 'application/vnd.conekta-v2.0.0+json',
                            'Content-Type': 'application/json',
                            Authorization: `Basic ${Base64.btoa(`${CONEKTA_KEY}:`)}`,
                            'Accept-Language': 'es', // TODO: Change this to get language from translation
                            'Conekta-Client-User-Agent': JSON.stringify({
                                agent: 'Conekta iOS SDK',
                            }),
                        },
                    });
                    const token = await tokenRes.json();
                    const response = await SERVICES.addPaymenth(token.id);
                    if (!response) return errorView('Error al crear', 'Intentelo mas tarde');
                    setViewModalPaymenth(false);
                    fetchData();
                    setIsLoading(false);
                    // navigation.goBack();
                } catch (error) {
                    setIsLoading(false);
                }
            }
        }

    return (
        <Modal show={viewModalPaymenth} onHide={() => setViewModalPaymenth(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Completa el formulario</Modal.Title>
            </Modal.Header>
            <div className='modal-body'>
                <form>
                    <label className='form-text'>Nombre del tarjetahabiente</label>
                    <input className='form-control' onChange={({target}) => setName(target.value)} type={'text'} />
                    <label className='form-text'>Numero de tarjeta</label>
                    <input className='form-control' onChange={({target}) => setNumber(target.value)}/>
                    <label className='form-tex'>Mes de expiracion</label>
                    <input className='form-control w-25' onChange={({target}) => setExp_month(target.value)}/>
                    <label className='form-text' >Año de expiracion</label>
                    <input className='form-control w-25' onChange={({target}) => setExp_year(target.value)}/>
                    <label className='form-text' >CVC</label>
                    <input className='form-control w-25' onChange={({target}) => setCvc(target.value)}/>
                </form>
            </div>
            <Modal.Footer>
                <button className='btn btn-secondary'>
                    Cancelar
                </button>
                <button onClick={(e) => {e.preventDefault(); createPay()}} disabled={isLoading} className='btn btn-primary'>
                    Crear
                </button>

            </Modal.Footer>
        </Modal>
    )
}
