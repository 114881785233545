import React from 'react';
import { useHistory } from 'react-router-dom';
import './Cards.style.css';

export const Cards = ({ product }) => {
    const history = useHistory();
    return (
        <div onClick={() => history.push(`/Product/${product.id}`)} className='text-center'>
            <div className="card m-2 card-product-container">
                <img src={product.img} alt='img product' className='card-img-top' />
                <div className='card-body pt-2'>
                    <p className='card-subtitle product-name-subtitle'>{product.name}</p>
                    <p className='card-title priceText'>{'$' + Number(product.price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                </div>
            </div>
        </div>
    )
}
