import React, { useState } from "react";
import { SERVICES } from "../../../Services/Services";
import { AddressFormUpdate } from "../AddressFormUpdate/AddressFormUpdate";
import "./AddressCards.style.css";

export const AddressCards = ({ card, fetchData }) => {
  const [viewUpdateModal, setViewUpdateModal] = useState(false);
  const deleteAddress = async (e) => {
    e.preventDefault();
    await SERVICES.deleteAddress(card.id)
      .then((res) => {
        // console.log('res ->', res);
        fetchData();
      })
      .catch((error) => {
        // console.log('error ->', error)
      });
  };
  return (
    <div>
      <div className="jlcc-style p-2 row g-1 align-items-center rounded h-100 g-1">
        <p>
          {card.street}, #{card.ext_num}, {card.neighborhood},{" "}
          {card.postal_code}, {card.city} {card.state}
        </p>
        <div>
          <button
            onClick={() => setViewUpdateModal(true)}
            className="btn btn-outline-primary me-1"
          >
            Editar
          </button>
          <button onClick={deleteAddress} className="btn btn-outline-danger">
            Eliminar
          </button>
        </div>
      </div>
      <AddressFormUpdate
        fetchData={fetchData}
        data={card}
        viewUpdateModal={viewUpdateModal}
        setViewUpdateModal={setViewUpdateModal}
      />
    </div>
  );
};
