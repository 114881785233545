import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { SERVICES } from '../../../Services/Services';
import './ViewOrder.style.css';

export const ViewOrder = () => {
    const history = useHistory();
    const [order, setOrder] = useState({});
    const [client, setClient] = useState({});
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [address, setAddress] = useState({});
    const [deliverMethod, setDeliverMethod] = useState({});

    let { orderId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            console.log('id ->', orderId);
            await SERVICES.showOrders(orderId).then((res) => {
                console.log('res ->', res)
                setOrder(res);
                setClient(res.client);
                setProducts(res.concepts);
                setTotal(res.total);
                setAddress(res.address);
                setDeliverMethod(res.delivery_method)
            }).catch((error) => {
                console.log('error ->', error);
            })
        };
        fetchData();
    }, [])


    return (
        <div className='address-container'>
            <p className='fs-4 fw-bold text-center p-4 '>Numero de orden #{order.id}</p>
            <div className='d-flex justify-content-center'>
                <div className='view_order_container'>
                    <div className='d-flex justify-content-between'>
                        <p className='info_left'>Cliente:</p>
                        <p className='right_info'>{client.name} {client.lastname}</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className='info_left'>Fecha:</p>
                        <p className='right_info'>{moment(order.created_at).format('DD/MM/YYYY')}</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className='info_left'>Total:</p>
                        <p className='right_info'>${Number(total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className='info_left'>Fecha:</p>
                        <p className='right_info'>{moment(order.created_at).format('DD/MM/YYYY')}</p>
                    </div>
                    <div className=''>
                        <p className='info_left'>Dirrecion de envio:</p>
                        <p className='right_info'>{address.street}, {address.neighborhood}, {address.city}, {address.country}, Num. Ext: #{address.ext_num}, Num. Int: #{address.int_num}</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className='info_left'>Metodo de entrega:</p>
                        <p className='right_info'>{deliverMethod.name}</p>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <p className='info_left'>Precio de entrega:</p>
                        <p className='right_info'>${Number(deliverMethod.cost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                    </div>
                    <p className='fw-bold fs-5 my-3'>Lista de productos</p>
                    <div >
                        {products.map((item, index) => (
                            <div key={index} className='border-bottom p-2 d-flex mx-3'>
                                <img
                                    src={item.img}
                                    className={'image-product-order pe-1'}
                                    alt='img product'
                                />
                                <div>
                                <p>{item.name}</p>
                                <p>${Number(item.price).toFixed(2)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
