import React from 'react';
import { SERVICES } from '../../../Services/Services';
import './PaymenthListCard.style.css';

export const PaymenthListCard = ({ card, index, fetchData }) => {

    const deleteCard = async () => {
        console.log('card id->', card)
        await SERVICES.deletePaymenth(index).then((res) => {
            console.log('res ->', res);
            fetchData();
        }).catch((error) => {
            console.log('error ->', error)
        })
    }

    return (
        <div>
            <div className='border d-flex mb-1 p-2 rounded align-items-center'>
            <img src={require(card.brand === 'visa' ? '../../../Assets/visa.png' : card.brand ? 'mastercard' ? '../../../Assets/mastercard.png' : '../../../Assets/american-express.png' : '../../../Assets/card.png')} className="img-cards-paymenth" alt="..." />
            <div className='p-2 me-auto'>
                <p className=''>{card.name}</p>
                <p className=''>****{card.last4}</p>
            </div>
            <button onClick={(e) => {e.preventDefault(); deleteCard();}} className='btn btn-danger ms-auto'>
                Eliminar
            </button>
        </div>
        </div>
    )
}
