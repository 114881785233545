import React from "react";
import { Route, Redirect } from "react-router-dom";

const NoSessionRoutes = ({ component: Component, ...restOfProps }) => {
    const isAuthenticated = localStorage.getItem("USER");
    // console.log('no loged ->', isAuthenticated);
    return (
        <Route
            // {...restOfProps}
            render={(props) =>
                isAuthenticated  ? (
                    <Redirect to="/MyProfile" />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
}

export default NoSessionRoutes;
