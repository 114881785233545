import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import AppRoutes from "./Routes/AppRouter";
import { store } from "./Store/store";


function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
      <AppRoutes/>

      </Provider>
    </BrowserRouter>
  );
}

export default App;
