import React from 'react'

export const Returns = () => {
    return (
        <div className='container'>
            <p className='fs-3 text-center fw-bold py-3'>Politicas de Devolucion y Reembolso</p>
            <div className="d-flex flex-column justify-content-center mx-5 text-justify">
                <p className='mt-1'>Tenemos una política de devolución de 7 días, lo que significa que tiene 7 días después de recibir su equipo para solicitar una devolución. Para ser elegible para una devolución, su artículo debe estar en las mismas condiciones en que lo recibió, sin usar ni usar, con etiquetas y en su embalaje original.</p>
                <p className='mt-1'>También necesitará el recibo o comprobante de compra. Para iniciar una devolución, puede ponerse en contacto con nosotros en Smardepot.garantías@gmail.com</p>
                <p className='mt-1'>Si se acepta su devolución, le enviaremos una etiqueta de envío de devolución, así como instrucciones sobre cómo y dónde enviar su paquete.</p>
                <p className='mt-1'>No se aceptarán los artículos que nos devuelvan sin solicitar primero una devolución. Siempre puede contactarnos para cualquier pregunta de devolución en Smardepot.garantias@gmail.com</p>
                <p className='mt-1'>Daños y problemas Inspeccione su pedido al recibirlo y comuníquese con nosotros de inmediato si el artículo está defectuoso, dañado o si recibe el artículo incorrecto, para que podamos evaluar el problema y corregirlo.</p>
                <p className='fw-bold mt-5 mb-2'>Excepciones / artículos no retornables</p>
                <p>Desafortunadamente, no podemos aceptar devoluciones de artículos en oferta o tarjetas de regalo. También necesitará el recibo o comprobante de compra.</p>
                <p className='fw-bold my-2'>Reembolsos</p>
                <p>Le notificaremos una vez que hayamos recibido e inspeccionado su devolución, y le informaremos si el reembolso fue aprobado o no. Si se aprueba, se le reembolsará automáticamente en su método de pago original dentro de los 10 días hábiles. Recuerde que su banco o compañía de tarjeta de crédito puede tardar algún tiempo en procesar y publicar el reembolso también. Si han pasado más de 15 días hábiles desde que aprobamos su devolución, contáctenos en juliomarin9029@gmail.com.</p>
            </div>

        </div>
    )
}
