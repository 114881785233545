import React from 'react'

export const Shipment = () => {
    return (
        <div className='container'>
            <p className='fs-3 text-center fw-bold py-3'>Política de envío</p>
            <div className="d-flex flex-column justify-content-center mx-5 text-justify">
                <ul>
                    <li>Nuestro servicio de envíos se encuentra disponible únicamente en México. Por ningún motivo se realizan envíos internacionales.</li>
                    <li>Todas las compras realizadas serán enviadas por el servicio de paquetería que se encuentre disponible según la zona.</li>
                    <li>La entrega del equipo se realizará en el domicilio señalado por el usuario.</li>
                    <li>El tiempo de entrega será de 2 a 3 días hábiles, contados a partir de que se ha recibido el importe completo de la compra.</li>
                    <li>Los pedidos que se hagan después de la 4:00 p.m.(horario del pacifico) saldrán hasta el día siguiente.</li>
                    <li>En un plazo máximo de 48 horas siguientes a tu compra en línea, recibirás un correo con el número de guía para que puedas rastrear el producto. Esta política no aplica para días de puente, asuetos, buen fin y demás días no laborables.</li>
                </ul>
            </div>
        </div>
    )
}
