import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Cards } from '../../Layouts/Cards/Cards';
import { SERVICES } from '../../Services/Services';
import './Categories.style.css';

export const Categories = () => {
    const history = useHistory();
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([])
    const [subCategories, setSubCategories] = useState([]);
    let { idCategory } = useParams();

    const getProductsByCategories = async (item) => {
        console.log('item ->', item);
        let productos = await SERVICES.show_subCategorie(item.id);
        console.log('products =>', productos.products)
        setProducts(productos.products)
    };

    const fetchData = async () => {
        await SERVICES.getCategories().then(async (res) => {
            console.log('res ->', res);
            setCategories(res);
            let productos = await SERVICES.show_category(Number(idCategory));
            console.log('products =>', productos)
            setSubCategories(productos.sub_categories)
            setProducts(productos.products)
        }).catch((error) => {

        });
    }

    const showSubCategorie = async () => {

    };

    useEffect(() => {
        fetchData();
    }, [idCategory])

    return (
        <div >
            <div className=" dropdown d-flex justify-content-center my-2 ">
                <button className="asdj btn btn-outline-primary dropdown-toggle w-50" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Categorias
                </button>
                <ul className="dropdown-menu">
                    {subCategories.map((item, index) => (
                        <li className="dropdown-item" key={index}>
                            <p onClick={(e) => { e.preventDefault(); getProductsByCategories(item) }} className='p-title-categories'>{item.name}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='row m-2 categories_container'>
                <div className="col-2  categories-scroll-p">
                    {subCategories.map((item, index) => (
                        <p onClick={(e) => { e.preventDefault(); getProductsByCategories(item)}} className='p-title-categories' key={index}>{item.name}</p>
                    ))}
                </div>
                <div className="col-10">
                    <div className='row row-cols-1 row-cols-sm-3 row-cols-md-4'>
                        {products.map((item, index) => (
                            <Cards product={item} key={index} />
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}
