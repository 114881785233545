import React from 'react'

export const Privacy = () => {
    return (
        <div className='container'>
            <p className='fs-3 text-center fw-bold py-3'>Politicas de Privacidad</p>
            <div className="d-flex flex-column justify-content-center mx-5 text-justify">
                <p className='fw-bold'>INFORMACIÓN PERSONAL QUE RECOPILAMOS</p>
                <p>Cuando visita el Sitio, recopilamos automáticamente cierta información sobre su dispositivo, incluida información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies que están instaladas en su dispositivo. Además, a medida que navega por el Sitio, recopilamos información sobre las páginas web individuales o los productos que ve, las páginas web o los términos de búsqueda que lo remitieron al Sitio e información sobre cómo interactúa usted con el Sitio. Nos referimos a esta información recopilada automáticamente como “Información del dispositivo”.</p>
                <p className='mt-3'>Recopilamos Información del dispositivo mediante el uso de las siguientes tecnologías:</p>
                <p className='fw-bold mt-3'>COOKIES</p>
                <p>Aquí tienes una lista de cookies que utilizamos. Las enlistamos para que puedas elegir si quieres optar por quitarlas o no.</p>
                <p>_session_id, unique token, sessional, Allows Shopify to store information about your session (referrer, landing page, etc).</p>
                <p>_shopify_visit, no data held, Persistent for 30 minutes from the last visit, Used by our website provider’s internal stats tracker to record the number of visits</p>
                <p>_shopify_uniq, no data held, expires midnight (relative to the visitor) of the next day, Counts the number of visits to a store by a single customer.</p>
                <p>cart, unique token, persistent for 2 weeks, Stores information about the contents of your cart.</p>
                <p>_secure_session_id, unique token, sessional</p>
                <p>storefront_digest, unique token, indefinite If the shop has a password, this is used to determine if the current visitor has access.- Los “Archivos de registro” rastrean las acciones que ocurren en el Sitio y recopilan datos, incluyendo su dirección IP, tipo de navegador, proveedor de servicio de Internet, páginas de referencia/salida y marcas de fecha/horario.- Las “balizas web”, las “etiquetas” y los “píxeles” son archivos electrónicos utilizados para registrar información sobre cómo navega usted por el Sitio. sea corregida, actualizada o eliminada. Si desea ejercer este derecho, comuníquese con nosotros a través de la información de contacto que se encuentra a continuación.</p>
                <p className='fw-bold mt-3'>RETENCIÓN DE DATOS</p>
                <p>Cuando realiza un pedido a través del Sitio, mantendremos su Información del pedido para nuestros registros a menos que y hasta que nos pida que eliminemos esta información.</p>
                <p className='fw-bold mt-3'>CAMBIOS</p>
                <p>Podemos actualizar esta política de privacidad periódicamente para reflejar, por ejemplo, cambios en nuestras prácticas o por otros motivos operativos, legales o reglamentarios.</p>
                <p className='fw-bold mt-3'>CONTÁCTENOS</p>
                <p>Para obtener más información sobre nuestras prácticas de privacidad, si tiene alguna pregunta o si desea presentar una queja, contáctenos por correo electrónico a hola@smartdepotmx.com o por correo mediante el uso de la información que se proporciona a continuación:</p>
                <p>Boulevard Sinaloa Plaza Bonita #1061, Culiacán, SIN, 80060, México</p>
            </div>
        </div>
    )
}
